import { useCallback, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FormatCurrency, Modal, Button } from '@countr/ui'
import { CartUtils } from '@countr/utils'
import { Trans } from 'react-i18next'
import ArrowBack from '../components/ui/ArrowBack'
import ScreenSaver from '../components/ui/ScreenSaver'
import PaymentButton from '../components/ui/Checkout/PaymentButton'
import BasicPayment from '../components/ui/Checkout/BasicPayment'
import RunningPayment from '../components/ui/Checkout/RunningPayment'
import ResultPayment from '../components/ui/Checkout/ResultPayment'

import countrSdk from '../utils/Countr'
import { cancelNixCheck } from '../utils/asposqueue'
import { updateCart } from '../utils/cart'
import { sendToDesktopIpc } from '../utils/asposqueue'
import { setCart } from '../store/actions/resource'
import { setRequestAskForHelp } from '../store/actions/notifications'
import { setCurrentTransactionToPrint, setIsPaymentRunning } from '../store/actions/app'

import PAYMENT_INDICATION_IMAGE from '../assets/images/voorbeeld-afbeelding.png'
import './../styles/pages.scss'

const Payment = ({ handleProduct }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [hasJustOnePayment, setHasOnePayment] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [currentMethod, setCurrentMethod] = useState(false)
  const [isBasicPaymentRunning, setIsBasicPaymentRunning] = useState(false)
  const [isPaymentRunning, localSetIsPaymentRunning] = useState(false)
  const [isPaymentFinished, setIsPaymentFinished] = useState(false)
  const [transaction, setTransaction] = useState(null)
  const [openClearModal, setOpenClearModal] = useState(false)
  const timer = useRef(null)

  const { store, cart, device, settings } = useSelector(state => state.resource)

  const setTransactionToPrint = useCallback(
    transaction => dispatch(setCurrentTransactionToPrint(transaction)),
    [dispatch]
  )

  const emptyCart = useCallback(
    c => {
      if (!c.items.length) {
        return
      }

      const clenned = CartUtils.emptyCart(c)
      updateCart(countrSdk, clenned)
      dispatch(setCart(clenned))
    },
    [dispatch]
  )

  useEffect(() => {
    const handleLastTimeClicked = () => {
      clearInterval(timer.current)
      if (!isPaymentRunning && !isBasicPaymentRunning && !isPaymentFinished) {
        timer.current = setInterval(() => setOpenClearModal(true), 40000) // 40 sec timeout
      }
    }

    window.addEventListener('click', handleLastTimeClicked)
    // useRef value stored in .current property
    handleLastTimeClicked()
    return () => {
      window.removeEventListener('click', handleLastTimeClicked)
      clearInterval(timer.current)
    }
  }, [isBasicPaymentRunning, isPaymentFinished, isPaymentRunning])

  const toggleIsPaymentRunning = useCallback(
    running => dispatch(setIsPaymentRunning(running)),
    [dispatch]
  )

  const handleClick = useCallback(
    payment => {
      setTransactionToPrint(null)
      setCurrentMethod(payment)

      if (!!payment.extras) {
        localSetIsPaymentRunning(true)
      } else {
        setIsBasicPaymentRunning(true)
      }

      toggleIsPaymentRunning(true)
    },
    [setTransactionToPrint, toggleIsPaymentRunning]
  )

  useEffect(() => {
    // TODO: Ping desktop to see if it is available and show all methods or only not sdk methods
    const loadPayments = () => {
      const storePayments = store.options?.extraPaymentMethods || []
      const devicePayments = device.options?.extrapaymentmethods || []
      const payments = devicePayments.concat(storePayments)

      setPaymentMethods(payments)

      if (payments && payments.length && payments.length === 1) {
        setHasOnePayment(true)
        handleClick(payments[0])
      }
    }

    loadPayments()
  }, [store, device, handleClick])

  const handleScreenSaver = () => {
    setOpenClearModal(prevState => !prevState)
  }

  const handleCloseRunningPayment = (result = null) => {
    if (!!result) {
      setTransaction(result)
      emptyCart(cart)
      setIsPaymentFinished(true)
      setIsBasicPaymentRunning(false)
      localSetIsPaymentRunning(false)
      toggleIsPaymentRunning(false)
      return
    }

    handleCloseFinishedPayment()
  }

  const handleCloseFinishedPayment = () => {
    setIsPaymentFinished(false)
    setCurrentMethod(false)
    setIsBasicPaymentRunning(false)
    localSetIsPaymentRunning(false)
    toggleIsPaymentRunning(false)

    cancelNixCheck()
    dispatch(setRequestAskForHelp(false))

    if (!!transaction && !!transaction._id) {
      const lastKeyUsed = localStorage.getItem('lastIdUsed')
      if (!lastKeyUsed || lastKeyUsed !== transaction._id) {
        localStorage.setItem('lastIdUsed', transaction._id)
        setTransaction(null)
        navigate('/receipt-screen', { state: transaction })
      }
    } else {
      setTransaction(null)
      handleCancelCheckout()
    }
  }

  const handleCancelCheckout = () => {
    sendToDesktopIpc('asposQueueDispatcher', {
      statusId: 3537,
      type: 'EFTAbort',
      priority: 1,
      processed: false,
      actionRequired: false
    })

    navigate(-1)
  }

  const renderModalContent = () => {
    const isRunning =
      (isPaymentRunning || isBasicPaymentRunning) && !isPaymentFinished

    if (!isRunning) {
      return (
        <ResultPayment
          transaction={transaction}
          handleCloseFinishedPayment={handleCloseFinishedPayment}
        />
      )
    }

    return (
      <>
        {/* <LoadBar /> */}
        {isPaymentRunning ? (
          <RunningPayment
            store={store}
            cart={cart}
            payment={currentMethod}
            close={handleCloseRunningPayment}
          />
        ) : (
          <BasicPayment
            store={store}
            cart={cart}
            payment={currentMethod}
            close={handleCloseRunningPayment}
          />
        )}
      </>
    )
  }

  return (
    <>
      {!isPaymentRunning &&
      !isBasicPaymentRunning &&
      !isPaymentFinished &&
      openClearModal ? (
        <ScreenSaver
          handleScreenSaver={handleScreenSaver}
          handleProduct={handleProduct}
          settings={settings}
          payment
        />
      ) : (
        <div className="payment-page">
          <ArrowBack />
          {!hasJustOnePayment && (
            <>
              <div className="details">
                <div className="details-pay">
                  <p>
                    <Trans i18nKey="total" />
                  </p>
                  <p>
                    <FormatCurrency
                      value={cart.total}
                      currency={cart.currency.symbol}
                    />
                  </p>
                </div>
                <hr className="details-hr" />
                <div className="details-remaining">
                  <p>{<Trans i18nKey="remaining" />}: </p>
                  <p>
                    <FormatCurrency
                      value={cart.total}
                      currency={cart.currency.symbol}
                    />
                  </p>
                </div>
              </div>
              <div className="buttons">
                {paymentMethods.map(payment => (
                  <PaymentButton
                    key={payment.name}
                    payment={payment}
                    onClick={handleClick}
                  />
                ))}
              </div>

              <div className="cancel">
                <Button
                  label={<Trans i18nKey="cancel" />}
                  onClick={handleCancelCheckout}
                  color="secondary"
                  styles={{ padding: 15 }}
                />
              </div>
            </>
          )}

          {(isPaymentRunning || isBasicPaymentRunning || isPaymentFinished) && (
            <Modal
              handleClose={handleCloseRunningPayment}
              backdropClose={false}
              showCancelBtn={false}
              showConfirmBtn={false}
              confirmLabel={<Trans i18nKey="close" />}
              confirmAction={handleCloseFinishedPayment}>
                <div className='pay-image-modal'>
                  <span className='pay-img-text-up'><Trans i18nKey="pay_img_text_up" /></span>
                  <span className='pay-img-text-down'><Trans i18nKey="pay_img_text_down" /></span>
                  <img
                    width="100%"
                    height="100%"
                    src={PAYMENT_INDICATION_IMAGE}
                    alt="Payment Indication"
                  />
                </div>
              {renderModalContent()}
            </Modal>
          )}
        </div>
      )}
    </>
  )
}

export default Payment
