import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { ProductUtils, BarcodeUtils } from '@countr/utils'
import onScan from 'onscan.js'

import { getCookie } from '../utils/cookies'
import { request } from '../utils/request'
import countrSdk from '../utils/Countr'
import { extractLocalPrices } from '../utils/utils'
import { sendToDesktopIpc } from '../utils/asposqueue'

import { setIsCalculatingCart } from '../store/actions/app'
import {
  setSelectedBarcode,
  setBarcodeProductNotFound
} from '../store/actions/resource.js'

const ACCESS_TOKEN = 'access_token'
const BARCODE_ENDPOINT = 'products/barcode'

const Scan = ({ merchantId, cart, handleProduct, isWelcome }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isPaymentRunning = useSelector(state => state.app.isPaymentRunning)
  const store = useSelector(state => state.resource.store)

  const goToMain = useCallback(() => {
    navigate('/main')
  }, [navigate])

  useEffect(() => {
    const init = async () => {
      if (!merchantId) {
        throw Error('Failed to instantiate IndexedDB to perform Scan')
      }

      console.log('Scanner running...')
    }

    init()
  }, [merchantId, cart._id])

  const toggleIsCalculatingCart = useCallback(
    show => dispatch(setIsCalculatingCart(show)),
    [dispatch]
  )

  const handleScan = useCallback(
    async code => {
      console.log(`Scanning ${code}`)
      if (isPaymentRunning) return
      if (isWelcome) {
        isWelcome(true)
      }

      toggleIsCalculatingCart(true)

      function handleScanProduct(product, order) {
        if (!(code.length >= 10 && code[0] === '2'))
          product.variants = product.variants.map(variant =>
            extractLocalPrices(variant, store._id)
          )
        else {
          product.variants = product.variants.map(variant => {
            variant.local_prices = []
            return variant
          })
          product.local_prices = []
        }

        product.addons = product.addons.map(addon =>
          extractLocalPrices(addon, store._id)
        )

        handleProduct(product, order)
      }

      const token = getCookie(ACCESS_TOKEN)
      const query = {
        text: code.length >= 10 && code[0] === '2' ? code.substr(0, 6) : code
      }

      // Add the scan query here later for Dexie
      // const dbProduct = await WorkerResourceLoader.searchDbByFields(code, ['ean'], 'products')

      const serverProducts = await request({
        token,
        query,
        resource: BARCODE_ENDPOINT
      })
      if (serverProducts?.length) {
        if (code.length >= 10 && code[0] === '2') {
          // Start with 2 and we do a full Match against the barcode
          const product = serverProducts.filter(_product => {
            return (
              _product.variants.filter(variant => {
                const eans = (variant.ean || '').split(',')
                return eans.indexOf(code.toLowerCase()) >= 0
              }).length > 0
            )
          })

          if (!!product && product.length) {
            product[0].variants = product[0].variants.map(el => {
              return {
                ...el,
                extras: {
                  ...el.extras,
                  scannedCode: code
                }
              }
            })
            handleScanProduct(product[0], [
              ProductUtils.PRODUCT_ACTION_ORDER.DEFAULT
            ])
          } else {
            // If the full match doesn't work
            // we search for the first 6 chars against the variants.ean first 6 chars
            // But that also ends with 000000
            const asposRecheckDynamic = serverProducts.filter(_product => {
              return (
                _product.variants.filter(variant => {
                  const eans = (variant.ean || '').split(',')
                  let eanFound = false
                  eans.forEach(eanval => {
                    if (
                      eanval.length >= 5 &&
                      code
                        .substring(0, 6)
                        .toLowerCase()
                        .indexOf(eanval.substring(0, 6).toLowerCase()) >= 0 &&
                      eanval.endsWith('000000') &&
                      eanval.length === code.length 
                    )
                      eanFound = true
                  })

                  return eanFound
                }).length > 0
              )
            })

            // If asposRecheckDynamic results in more than one match, then check 7 characters to see if that narrows it down
            let finalArray = []
            if (asposRecheckDynamic.length > 1) {
              finalArray = serverProducts.filter(_product => {
                return (
                  _product.variants.filter(variant => {
                    const eans = (variant.ean || '').split(',')
                    let eanFound = false
                    eans.forEach(eanval => {
                      if (
                        eanval.length >= 5 &&
                        code
                          .substring(0, 7)
                          .toLowerCase()
                          .indexOf(eanval.substring(0, 7).toLowerCase()) >= 0 &&
                        eanval.endsWith('000000') &&
                        eanval.length === code.length
                      ) {
                        eanFound = true
                      }
                    })

                    return eanFound
                  }).length > 0
                )
              })
            }

            finalArray = finalArray.length ? finalArray : asposRecheckDynamic

            // And we call dynamic for it
            if (finalArray && finalArray[0]) {
              // Calculate price and handle product as normal

              const calculated_price =
                parseInt(code.slice(-5, code.length - 1)) / 100
              finalArray[0].variants[0].price = finalArray[0].price = !isNaN(
                calculated_price
              )
                ? parseFloat(calculated_price)
                : parseFloat(finalArray[0].variants[0].price)

              finalArray[0].variants =
                finalArray[0].variants.map(el => {
                  return {
                    ...el,
                    extras: {
                      ...el.extras,
                      scannedCode: code
                    }
                  }
                })

              handleScanProduct(finalArray[0], [
                ProductUtils.PRODUCT_ACTION_ORDER.DEFAULT
              ])
            } else {
              dispatch(setBarcodeProductNotFound(true))

              sendToDesktopIpc('asposQueueDispatcher', {
                statusId: 3537,
                type: 'UnknownBarcode',
                priority: 1,
                processed: false,
                actionRequired: false,
                extras: {
                  info: `Scan code unknown ${code}`
                }
              })

              BarcodeUtils.barcodeNotFound(countrSdk, cart, code)

              setTimeout(() => {
                toggleIsCalculatingCart(false)
              }, 5000)
            }
          }
        } else {
          serverProducts[0].variants = serverProducts[0].variants.map(el => {
            return {
              ...el,
              extras: {
                ...el.extras,
                scannedCode: code
              }
            }
          })

          handleScanProduct(
            serverProducts[0],
            ProductUtils.getProductActionOrder(serverProducts[0])
          )
        }
      } else {
        toggleIsCalculatingCart(false)
        BarcodeUtils.barcodeNotFound(countrSdk, cart, code)
        dispatch(setBarcodeProductNotFound(true))
        sendToDesktopIpc('asposQueueDispatcher', {
          statusId: 3537,
          type: 'UnknownBarcode',
          priority: 1,
          processed: false,
          actionRequired: false,
          extras: {
            info: `Scan code unknown ${code}`
          }
        })
      }

      goToMain()
      dispatch(setSelectedBarcode(code))
    },
    [
      cart,
      dispatch,
      goToMain,
      handleProduct,
      isPaymentRunning,
      isWelcome,
      store._id,
      toggleIsCalculatingCart
    ]
  )

  useEffect(() => {
    if (onScan?.isAttachedTo(document)) {
      return
    }
    onScan.attachTo(document, {
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      reactToPaste: false,
      onScan: code => handleScan(code),
      minLength: 4
    })
    return () => {
      if (document.scannerDetectionData) {
        onScan.detachFrom(document)
      }
    }
  }, [handleScan])
}

export default Scan
