import {
  SET_LOADING,
  SET_THEME,
  OPEN_SCREEN_SAVER,
  SET_SHOW_NIX,
  AUTODIRECT_PAYMENT,
  WAIT_AUTODIRECT_PAYMENT,
  SET_CURRENT_TRANSACTION_TO_PRINT,
  IS_CALCULATING_CART,
  IS_PAYMENT_RUNNING,
  SET_SHOW_PRODUCT_DESCRIPTION,
  SET_SHOW_FREE_PRODUCT
} from './actionTypes'

export const setIsPaymentRunning = running => ({
  type: IS_PAYMENT_RUNNING,
  payload: running
})

export const setIsCalculatingCart = loading => ({
  type: IS_CALCULATING_CART,
  payload: loading
})

export const setLoading = loading => ({
  type: SET_LOADING,
  payload: loading
})

export const setTheme = theme => ({
  type: SET_THEME,
  payload: theme
})

export const setCurrentTransactionToPrint = transaction => ({
  type: SET_CURRENT_TRANSACTION_TO_PRINT,
  payload: transaction
})

export const setOpenScreensaver = open => ({
  type: OPEN_SCREEN_SAVER,
  payload: open
})

export const setShowNix = show => ({
  type: SET_SHOW_NIX,
  payload: show
})

export const setShowFreeProduct = show => ({
  type: SET_SHOW_FREE_PRODUCT,
  payload: show
})

export const setShowProductDescription = description => ({
  type: SET_SHOW_PRODUCT_DESCRIPTION,
  payload: description
})

export const setAutodirectPayment = auto => ({
  type: AUTODIRECT_PAYMENT,
  payload: auto
})

export const setWaitAutodirectPayment = wait => ({
  type: WAIT_AUTODIRECT_PAYMENT,
  payload: wait
})
