import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import countrSdk from '../utils/Countr'
import { setCookie, getCookie } from '../utils/cookies'
import {
  checkDevice,
  getLastTransaction,
  loadDeviceSettings
} from '../utils/device'
import { getCart } from '../utils/cart'
import { getCurrentTheme, hasTheme } from '../utils/store'
import { sendToDesktopIpc } from '../utils/asposqueue'

import { setLoading, setTheme } from '../store/actions/app'
import { setUUID, setAllResources } from '../store/actions/resource'

import './../styles/pages.scss'

const Landing = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setLoadingCb = useCallback(l => dispatch(setLoading(l)), [dispatch])
  const setAllCb = useCallback(r => dispatch(setAllResources(r)), [dispatch])
  const setUUIDCb = useCallback(u => dispatch(setUUID(u)), [dispatch])
  const setThemeCb = useCallback(t => dispatch(setTheme(t)), [dispatch])
  const checkTheme = useCallback(
    store => {
      const currentTheme = getCurrentTheme(store)
      setThemeCb(currentTheme)
    },
    [setThemeCb]
  )

  useEffect(() => {
    const init = async () => {
      setLoadingCb(true)
      let uuid = getCookie('uuid')
      const access_token = getCookie('access_token')
      const refresh_token = getCookie('refresh_token')

      if (
        !!access_token &&
        access_token !== '' &&
        !!refresh_token &&
        refresh_token !== ''
      ) {
        countrSdk.setToken({
          access_token: access_token,
          refresh_token: refresh_token
        })
      } else {
        setLoadingCb(false)
        navigate('/login')
      }

      if (!uuid || !uuid.includes('kiosk-')) {
        // const fp = await FingerprintJS.load()
        const fp = await FingerprintJS.load({ token: 'qWrdj7r6z8RlqD9KlAwy' })
        // const result = await fp.get()
        const result = await fp.get({ extendedResult: true })
        uuid = `kiosk-${result.visitorId}`
      }

      setUUIDCb(uuid)
      setCookie('uuid', uuid)
      const device = await checkDevice(countrSdk, uuid)

      if (device) {
        const { merchant, store } = device

        const cart = await getCart(countrSdk, merchant._id, store._id, device)
        const lastTransaction = await getLastTransaction(countrSdk, device)
        const settings = loadDeviceSettings(device)

        setAllCb({
          user: merchant,
          uuid,
          device,
          store,
          cart,
          lastTransaction: lastTransaction ?? 0,
          settings
        })

        if (hasTheme(store)) {
          checkTheme(store)
        }

        setLoadingCb(false)
        navigate('/welcome-page')

        sendToDesktopIpc('bootstrapAsposQueue', { device, store }, true)
      } else {
        setLoadingCb(false)
        navigate('/login')
      }
    }

    init()
  }, [navigate, setLoadingCb, setUUIDCb, setAllCb, checkTheme])

  return <div className="landing-page"></div>
}

export default Landing
