import { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdInfoOutline } from 'react-icons/md'

import { ProductUtils } from '@countr/utils'

import {
  setIsCalculatingCart,
  setShowProductDescription
} from '../../../store/actions/app'

import { setSearch } from '../../../store/actions/resource'

import '../../../styles/cart.scss'

const ProductTile = ({ product, store, handleResult, childHeight }) => {
  const dispatch = useDispatch()

  const { settings } = useSelector(state => state.resource)

  const toggleIsCalculatingCart = useCallback(
    show => dispatch(setIsCalculatingCart(show)),
    [dispatch]
  )

  const setSearchCb = useCallback(s => dispatch(setSearch(s)), [dispatch])

  const settingShowProductDescription = useCallback(
    (show, description, currentProductName) =>
      dispatch(
        setShowProductDescription({ show, description, currentProductName })
      ),
    [dispatch]
  )

  const productName = useMemo(
    () => ProductUtils.getProductName(product, settings?.language),
    [product, settings.language]
  )

  const handleClick = () => {
    toggleIsCalculatingCart(true)

    setSearchCb('')
    handleResult(product, ProductUtils.getProductActionOrder(product))
  }

  return (
    <div className="product-title-wrapper">
      <div
        className="product-box"
        onClick={handleClick}
        style={{ height: childHeight }}>
        <div
          className={childHeight < 300 ? 'product-img-small' : 'product-img'}>
          <img
            src={product.image?.length ? product.image : store.store_logo}
            alt={productName}
            title={productName}
          />
        </div>
        <div className="product-title">
          <span style={childHeight < 300 ? { fontSize: 12 } : {}}>
            {productName}
          </span>
        </div>
        <div className="product-price">
          <span style={childHeight < 300 ? { fontSize: 12 } : {}}>
            {store.currency?.symbol}
            {parseFloat(ProductUtils.getProductPrice(
              product,
              product?.variants[0]?._id,
              store._id
            )).toFixed(2)}
          </span>
        </div>
      </div>
      {product.description && (
        <i
          onClick={() => {
            settingShowProductDescription(
              true,
              product.description,
              productName
            )
          }}>
          <MdInfoOutline />
        </i>
      )}
    </div>
  )
}

export default ProductTile
