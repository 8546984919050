import {
  SET_THEME,
  SET_LOADING,
  SET_SHOW_NIX,
  OPEN_SCREEN_SAVER,
  AUTODIRECT_PAYMENT,
  IS_CALCULATING_CART,
  WAIT_AUTODIRECT_PAYMENT,
  SET_CURRENT_TRANSACTION_TO_PRINT,
  IS_PAYMENT_RUNNING,
  SET_SHOW_PRODUCT_DESCRIPTION,
  SET_SHOW_FREE_PRODUCT
} from './../actions/actionTypes'

const initialState = {
  loading: false,
  theme: {},
  openScreenSaver: false,
  showNix: false,
  showFreeProduct: false,
  showProductDescription: {
    show: false,
    description: 'testing...',
    currentProductName: ''
  },
  autodirectPayment: false,
  waitAutoDirectPayment: false,
  currentTransactionToPrint: null,
  isCalculatingCart: false,
  isPaymentRunning: false
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_CALCULATING_CART:
      return {
        ...state,
        isCalculatingCart: action.payload
      }

    case IS_PAYMENT_RUNNING:
      return {
        ...state,
        isPaymentRunning: action.payload
      }

    case SET_SHOW_NIX:
      return {
        ...state,
        showNix: action.payload
      }

    case SET_SHOW_FREE_PRODUCT:
      return {
        ...state,
        showFreeProduct: action.payload
      }

    case SET_SHOW_PRODUCT_DESCRIPTION:
      return {
        ...state,
        showProductDescription: action.payload
      }

    case SET_CURRENT_TRANSACTION_TO_PRINT:
      return {
        ...state,
        currentTransactionToPrint: action.payload
      }

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_THEME:
      return {
        ...state,
        theme: action.payload
      }
    case OPEN_SCREEN_SAVER:
      return {
        ...state,
        openScreenSaver: action.payload
      }
    case AUTODIRECT_PAYMENT:
      return {
        ...state,
        autodirectPayment: action.payload
      }
    case WAIT_AUTODIRECT_PAYMENT:
      return {
        ...state,
        waitAutoDirectPayment: action.payload
      }
    default:
      return state
  }
}

export default appReducer
