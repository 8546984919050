import React, { useCallback, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { MdArrowBack } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import countrSdk from '../../utils/Countr'
import { hasAdminPin, getAdminPin } from '../../utils/settings'
import { sendToDesktopIpc } from '../../utils/asposqueue'
import { logError } from '../../utils/error'
import { setRequestAskForHelp } from '../../store/actions/notifications'
import LanguageSelection from './Language/LanguageSelection'
import AdminPIN from './Settings/AdminPIN'

import Logo from './Logo'
import './../../styles/ui.scss'

const Header = React.memo(props => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { settings, requestAskForHelp } = useSelector(state => state.resource)
  const { openScreenSaver } = useSelector(state => state.app)
  const [showAdminPIN, setShowAdminPIN] = useState(false)
  const [count, setCount] = useState(0)
  const [showBackArrow, setShowBackArrow] = useState(false)

  const { device, store, merchant } = useSelector(state => state.resource)

  const requestAskHelp = useCallback(
    askHelp => dispatch(setRequestAskForHelp(askHelp)),
    [dispatch]
  )

  const goToSettings = () => {
    if (count === 2) {
      setCount(0)

      if (hasAdminPin(settings)) {
        setShowAdminPIN(true)
      } else {
        handleConfirmAdminPin()
      }
    } else {
      setCount(count + 1)
    }
  }

  const handleClickBack = () => {
    navigate('/main')
    setShowBackArrow(false)
  }

  const handleCancelAdminPin = () => {
    setShowAdminPIN(false)
  }

  const handleConfirmAdminPin = () => {
    handleCancelAdminPin()
    navigate('/settings')
    setShowBackArrow(true)
  }

  const callForHelp = () => {
    try {
      sendToDesktopIpc('asposQueueDispatcher', {
        type: 'Help',
        priority: 1,
        processed: false,
        actionRequired: true
      })
    } catch (e) {
      logError(
        countrSdk,
        merchant._id,
        store._id,
        device._id,
        'Failed asposQueueDispatcher',
        e
      )
    }
    requestAskHelp(true)
  }

  return (
    <div id="app-container">
      <header className="app-header">
        {showBackArrow ? (
          <MdArrowBack className="back-arrow" onClick={handleClickBack} />
        ) : (
          <Logo onClick={goToSettings} />
        )}
        {(!openScreenSaver || !settings.enable_screen_saver) &&
        location?.pathname !== '/welcome-page' ? (
          <div className="lang-selection">
            <LanguageSelection />
          </div>
        ) : (
          <div className="ask-help">
            <button
              disabled={requestAskForHelp}
              className={`kiosk-btn-container ${
                requestAskForHelp
                  ? 'asking-for-help-btn'
                  : 'light-contained-btn'
              }`}
              onClick={callForHelp}>
              {requestAskForHelp ? t('help_is_coming') : t('ask_for_help')}
            </button>
          </div>
        )}
        {showAdminPIN && (
          <AdminPIN
            handleCancel={handleCancelAdminPin}
            handleConfirm={handleConfirmAdminPin}
            adminPin={getAdminPin(settings)}
          />
        )}
      </header>
      <div id="children-container">{props.children}</div>
    </div>
  )
})

export default Header
