import { TransactioUtils } from '@countr/utils'
import { logError } from './error'
import { PrinterUtils } from './printer'

export const createTransaction = (sdk, body, cartId) => {
  
  try {
    //Set status of each 'new' item to 'pending'
    body.items.forEach(function(item){
      item.status = item.status || []
      const newStatus = item.status.find((el) => el.state === 'new')
      const pendingStatus = item.status.find((el) => el.state === 'pending')
      if(!pendingStatus) {
        item.status.push({
          last_update: new Date(),
          state: 'pending',
          amount: newStatus.amount,
          employees: []
        })
      } else {
        pendingStatus.amount = newStatus.amount
        pendingStatus.last_update = new Date()
      }
      newStatus.amount = 0
    })
    body.extras.deviceCartName = body.receipt_id
    body.extras.extension = localStorage.getItem('CountrLite:LastTransaction')
    body.extras.delivery = {
      "courier" : {
          "deliveryBy" : "kiosk"
      },
      "messageAlerts" : true,
      "deliveryAddress" : {
          "city" : "",
          "extraAddressInfo" : "",
          "postalCode" : "",
          "street" : "",
          "streetNumber" : ""
      },
      "deliveryIsAsap" : true,
      "pickupTime" : new Date(),
      "deliveryCost" : 0,
      "tipAmount" : 0,
      "print" : {
          "deliveredBy" : "kiosk",
          "pickupTime" : "ASAP",
          "customerPhone" : "",
          "customerName" : "",
          "deliveryPostcode" : "",
          "deliveryCity" : "",
          "extraAddressInfo" : ""
      }
    }
    body.extras.orderSource = cartId

    return TransactioUtils.createTransaction(sdk, body)
  } catch (error) {
    const { merchant, store, device, payments } = body
    const msg = `Transaction receipt #${body.receipt_id} fail to create (Payment: $ ${payments[0].paid} - ${payments[0].provider})`
    logError(sdk, merchant, store, device, msg, body)
    return Promise.reject(error)
  }
}

export const printTransaction = (device, store, merchant, transaction, returnPrintCallback, translate) => {
  const local = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
  if(device?.settings?.auto_print && local) {
    const copyTransaction = { ...transaction, merchant: merchant._id }
    const receipt = merchant.receipt
    const currency = transaction.currency.code
    const printEAN = false
    const printQR = false
    const openCashDrawer = false
    PrinterUtils.receipt(
      device,
      copyTransaction,
      receipt,
      store,
      currency,
      returnPrintCallback,
      printEAN,
      printQR,
      openCashDrawer,
      translate
    )
  }
}
