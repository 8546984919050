// import countrLog from '@countr/slack'

export function logSlack(msg) {
  console.log('🚀 ~ file: utils.js:4 ~ logSlack ~ msg', msg)
  // if (process.env.REACT_APP_ERROR_SLACK_WEBHOOK_SPAR_ENABLED === 'true') {
  //   countrLog.logSlack(
  //     process.env.REACT_APP_ERROR_SLACK_WEBHOOK_SPAR,
  //     `${process.env.NODE_ENV} - ${process.env.REACT_APP_ERROR_SOURCE} - ${JSON.stringify(
  //       msg
  //     )}`
  //   )
  // }
}

export function colorToHex(color) {
  const colors = {
    black: '#000000',
    grey: '#CCCCCC',
    darkgrey: '#666666',
    darkestgrey: '#333333',
    white: '#FFFFFF',
    darkblue: '#34495E',
    greyblue: '#B6C2CD',
    lightblue: '#00ADEE',
    blue: '#136F8C',
    lightgreen: '#1BBC9B',
    darkgreen: '#26A560',
    purple: '#CA87E6',
    pink: '#F89BBA',
    red: '#E26A6A',
    yellow: '#FFD65C',
    brown: '#B58930'
  }

  if (!color) {
    return colors.greyblue
  } else if (color.indexOf('#') >= 0) {
    return color
  } else if (typeof colors[color.toLowerCase()] !== 'undefined') {
    return colors[color.toLowerCase()]
  } else {
    return colors.greyblue
  }
}

export function isBgColorlightOrDark(color) {
  let r = null
  let g = null
  let b = null

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  // If bgColor light, font color is back, otherwise it is white
  if (hsp > 127.5) {
    // return 'light'
    return '#000000'
  } else {
    // return 'dark'
    return '#FFFFFF'
  }
}

export function extractLocalPrices(data, storeId) {
  const local_price = (data.local_prices || []).find(
    localPrice => localPrice.store.toString() === storeId
  )

  if (local_price) {
    data.price = local_price.price
  }

  return data
}
