import { useRef, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { ProductUtils, AddonUtils } from '@countr/utils'
import { Modal, Variants, Addons, Dynamic } from '@countr/ui'
import { Trans, useTranslation } from 'react-i18next'

import { setAutodirectPayment } from '../store/actions/app'

import AddonsGroupModal from '../components/ui/AddonsGroupModal/AddonsGroupModal'

import { useEffectOnce } from '../utils/hooks'
import { PrinterUtils } from '../utils/printer'

import countrSdk from '../utils/Countr'
import ScreenSaver from '../components/ui/ScreenSaver'
import SparCart from '../components/ui/SparCart'
import SampleCheckModal from '../components/ui/SampleCheckModal'
import './../styles/pages.scss'

const Main = ({
  setSoldByWeightAmount,
  handleProduct,
  modalContent,
  selectedProduct,
  showModal,
  selectedVariant,
  selectedAddons,
  dynamicValue,
  soldByWeightAmount,
  handleCancel,
  handleSelectVariant,
  setSelectedAddons,
  setCartCb,
  showNoBarcode,
  setShowNoBarcode,
  addProduct,
  setDynamicValue,
  indexedDBInstance
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const {
    user: merchant,
    store,
    device,
    cart,
    settings
  } = useSelector(state => state.resource)

  const {
    requestSampleCheck,
    requestAskForHelp,
    requestFullBasketCheck,
    fullBasketCart
  } = useSelector(state => state.notifications)

  const { autodirectPayment, waitAutoDirectPayment } = useSelector(
    state => state.app
  )

  const [openClearModal, setOpenClearModal] = useState(false)
  const timer = useRef(null)

  const handleScreenSaver = () => {
    setOpenClearModal(prevState => !prevState)
  }

  const resetLamp = useCallback(() => {
    PrinterUtils.sendToPrinter(
      device,
      'lamps',
      {
        r: 0,
        g: 0,
        b: 255,
        blink: false
      },
      {}
    )
  }, [device])

  useEffectOnce(() => {
    resetLamp()
  }, [resetLamp])

  const returnProductActionType = () => {
    const { VARIANTS, ADDONS, DYNAMIC, SOLD_BY_WEIGHT } =
      ProductUtils.PRODUCT_ACTION_ORDER

    switch (modalContent) {
      case VARIANTS:
        // If product has more than one variant, and one of them is default
        const hasDefaultVariant =
          selectedProduct.variants.length &&
          selectedProduct.variants.filter(
            (variant, i) => variant.name === 'Default'
          ).length

        let clonedProduct = selectedProduct

        if (hasDefaultVariant) {
          clonedProduct = {
            ...selectedProduct,
            // We remove the default variant from the list
            variants: selectedProduct.variants.filter(
              variant => variant.name !== 'Default'
            )
          }
        }

        return (
          <Variants
            product={clonedProduct}
            handleClick={handleSelectVariant}
            store={store}
            language={i18n.language}
          />
        )
      case ADDONS:
        if (isAddonsGroup()) {
          const valid = selectedProduct.addonGroups.map(group =>
            AddonUtils.isGroupValid(group)
          )

          return valid.indexOf(false) >= 0 ? (
            <div>
              <span>
                <Trans i18nKey="corrupted_addons" />
              </span>
            </div>
          ) : (
            <AddonsGroupModal
              product={selectedProduct}
              handleChange={setSelectedAddons}
              store={store}
              language={i18n.language}
            />
          )
        }

        return (
          <Addons
            product={selectedProduct}
            handleChange={setSelectedAddons}
            store={store}
            language={i18n.language}
          />
        )
      case DYNAMIC:
        return (
          <Dynamic
            product={selectedProduct}
            handleChange={setDynamicValue}
            store={store}
          />
        )
      case SOLD_BY_WEIGHT:
        return (
          <Dynamic
            product={selectedProduct}
            handleChange={setSoldByWeightAmount}
            store={store}
            isSoldByWeight={true}
          />
        )
      default:
        return null
    }
  }

  const isAddonsGroup = () => {
    return (
      !ProductUtils.hasAddons(selectedProduct) &&
      ProductUtils.hasAddonGroups(selectedProduct)
    )
  }

  const validateAllGroups = () => {
    if (!selectedAddons) {
      return false
    }

    const isValid = []
    selectedAddons.forEach(group => {
      const list = [...group._id.addons]
      isValid.push(AddonUtils.validateGroup(list, group))
    })

    return isValid.indexOf(false) < 0
  }

  const handleSelectAddons = () => {
    const addons = isAddonsGroup()
      ? selectedAddons
          .map(group => {
            return group._id.addons.filter(addon => addon.amount)
          })
          .flat()
      : selectedAddons
      ? selectedAddons.filter(addon => addon.amount)
      : []

    const product = JSON.parse(JSON.stringify(selectedProduct))
    const variant = selectedVariant ? selectedVariant : product.variants[0]
    const amount = soldByWeightAmount > 0 ? soldByWeightAmount : 1

    if (modalContent === ProductUtils.PRODUCT_ACTION_ORDER.DYNAMIC) {
      const varIndex = product.variants.findIndex(v => v._id === variant._id)
      product.variants[varIndex].price = parseFloat(dynamicValue)
    }

    addProduct(product, variant, store._id, amount, addons)
    handleCancel()
  }

  const showConfirm = () => {
    const { ADDONS, DYNAMIC, SOLD_BY_WEIGHT } =
      ProductUtils.PRODUCT_ACTION_ORDER
    return (
      modalContent === ADDONS ||
      modalContent === DYNAMIC ||
      modalContent === SOLD_BY_WEIGHT
    )
  }

  useEffect(() => {
    const handleLastTimeClicked = () => {
      clearInterval(timer.current)
      if (
        !requestSampleCheck &&
        !requestAskForHelp &&
        !requestFullBasketCheck
      ) {
        timer.current = setInterval(() => setOpenClearModal(true), 40000) // 40 sec timeout
      }
    }
    window.addEventListener('click', handleLastTimeClicked)
    // useRef value stored in .current property
    handleLastTimeClicked()

    if (settings?.language) {
      i18n.changeLanguage(settings.language)
    }

    // cleanup this component
    return () => {
      window.removeEventListener('click', handleLastTimeClicked)
      clearInterval(timer.current)
    }
  }, [
    cart,
    settings,
    timer,
    i18n,
    requestSampleCheck,
    requestAskForHelp,
    requestFullBasketCheck
  ])

  useEffect(() => {
    if (
      autodirectPayment &&
      !waitAutoDirectPayment &&
      !requestSampleCheck &&
      !requestFullBasketCheck
    ) {
      dispatch(setAutodirectPayment(false))
      navigate('/payment')
    }
  }, [
    autodirectPayment,
    dispatch,
    navigate,
    requestFullBasketCheck,
    requestSampleCheck,
    waitAutoDirectPayment
  ])

  return (
    <>
      {!requestFullBasketCheck &&
      !requestSampleCheck &&
      !requestAskForHelp &&
      openClearModal ? (
        <ScreenSaver
          handleScreenSaver={handleScreenSaver}
          handleProduct={handleProduct}
          settings={settings}
        />
      ) : (
        <div className="main-page">
          <SparCart
            indexedDBInstance={indexedDBInstance.current}
            cart={requestFullBasketCheck ? fullBasketCart : cart}
            store={store}
            merchantId={merchant._id}
            countrSdk={countrSdk}
            setCartCb={setCartCb}
            handleProduct={handleProduct}
            showNoBarcode={showNoBarcode}
            setShowNoBarcode={setShowNoBarcode}
            requestFullBasketCheck={requestFullBasketCheck}
          />
          {showModal && (
            <Modal
              title={selectedProduct.name}
              handleClose={handleCancel}
              confirmAction={handleSelectAddons}
              showConfirmBtn={showConfirm()}
              disableConfirm={isAddonsGroup() && !validateAllGroups()}
              cancelLabel={<Trans i18nKey="cancel" />}>
              {returnProductActionType()}
            </Modal>
          )}

          {requestSampleCheck && <SampleCheckModal />}
        </div>
      )}
    </>
  )
}

export default Main
