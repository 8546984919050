import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryName } from '../../../utils/product'
import { setSelectedCategory } from '../../../store/actions/resource'
import { colorToHex, isBgColorlightOrDark } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'
import '../../../styles/categories.scss'

const CategoryTile = ({ category, setShowNoBarcode }) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { selectedCategory } = useSelector(state => state.resource)

  const getCategoryColor = category => {
    if (category !== undefined && category.color !== undefined) {
      return colorToHex(category.color)
    } else {
      return '#B6C2CD'
    }
  }

  const fontColor = useMemo(() => {
    return isBgColorlightOrDark(getCategoryColor(category))
  }, [category])

  const returnButtonStyle = cat => {
    return {
      backgroundColor: cat.image ? '#FFFFFF ' : getCategoryColor(cat),
      color: fontColor,
      backgroundImage: 'url(' + cat.image + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 120px',
      backgroundPosition: 'center'
    }
  }

  const categoryHandler = cat => {
    dispatch(setSelectedCategory(cat))
    setShowNoBarcode(false)
  }

  const CategoryTileConstructor = ({ cat }) => (
    <div
      className="category-box"
      id={cat._id}
      onClick={() => categoryHandler(cat)}
      style={returnButtonStyle(cat)}>
      <div className="category-name">
        <span>{getCategoryName(cat, i18n?.language)}</span>
      </div>
    </div>
  )

  return (
    <>
      {(!selectedCategory?.isChild &&
        category.children &&
        selectedCategory?._id === category._id) ||
      (selectedCategory?.isChild &&
        category.children.some(child => child._id === selectedCategory._id)) ? (
        <>
          <CategoryTileConstructor cat={category} />

          {category.children.map(child => (
            <div key={child._id} className="child-category">
              <CategoryTileConstructor cat={child} />
            </div>
          ))}
        </>
      ) : (
        <CategoryTileConstructor cat={category} />
      )}
    </>
  )
}

export default CategoryTile
