import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { Modal } from '@countr/ui'

import WorkerResourceLoader from '../../../WorkerResourceLoader'
import { setLoading, setShowNix } from './../../../store/actions/app'

import { setProducts } from './../../../store/actions/resource'
import ProductTile from './ProductTile'
import Scan from '../../Scan'
import './../../../styles/products.scss'

const ProductList = ({
  merchantId,
  store,
  handleProduct,
  cart,
  requestFullBasketCheck,
  childHeight
}) => {
  const dispatch = useDispatch()

  const [showBarcodeNotFound, setShowBarcodeNotFound] = useState(null)
  const [refreshing, setRefreshing] = useState(false)

  const { search, selectedCategory, storeProducts, isSearchRunning } =
    useSelector(state => state.resource)

  const toggleShowNix = useCallback(
    show => dispatch(setShowNix(show)),
    [dispatch]
  )
  const setLoadingCb = useCallback(l => dispatch(setLoading(l)), [dispatch])
  const handleSetProducts = useCallback(
    d => dispatch(setProducts(d)),
    [dispatch]
  )

  useEffect(() => {
    if (search?.length > 3 && isSearchRunning) {
      setLoadingCb(true)
      WorkerResourceLoader.searchDbByFields(search, ['name'], 'vproducts').then(
        products => {
          if (Array.isArray(products)) {
            console.log(`Total of Product found ${products.length}`)
            handleSetProducts(products)
          }

          setLoadingCb(false)
        }
      )
    } else if (selectedCategory._id) {
      WorkerResourceLoader.searchDbByFields(
        selectedCategory._id,
        ['i_categories'],
        'vproducts'
      ).then(products => {
        if (Array.isArray(products)) {
          console.log(`Total of Product found ${products.length}`)
          handleSetProducts(products)
        }
      })
    } else {
      handleSetProducts([])
    }
  }, [
    search,
    isSearchRunning,
    setLoadingCb,
    handleSetProducts,
    selectedCategory._id
  ])

  useEffect(() => {
    setRefreshing(true)
    setTimeout(() => {
      setRefreshing(false)
    }, 200)
  }, [storeProducts, refreshing])

  const handleCloseBarcodeNotFound = () => {
    setShowBarcodeNotFound(null)
  }

  return (
    <div className="product-list">
      {!!merchantId && !!cart?._id && (
        <Scan
          handleProduct={handleProduct}
          cart={cart}
          merchantId={merchantId}
          store={store}
        />
      )}
      {!!storeProducts?.length ? (
        storeProducts
          .sort((a, b) => a.position - b.position)
          .filter(product => product.visible)
          .map(product => (
            <ProductTile
              key={product._id}
              product={product}
              handleResult={handleProduct}
              store={store}
              toggleShowNix={toggleShowNix}
              requestFullBasketCheck={requestFullBasketCheck}
              childHeight={childHeight}
            />
          ))
      ) : (
        <div className="payment-button no-products">
          <Trans i18nKey="no_product_found" />
        </div>
      )}
      {showBarcodeNotFound && (
        <Modal
          title={<Trans i18nKey="scan_fail" />}
          handleClose={handleCloseBarcodeNotFound}
          showConfirmBtn={false}
          backdropClose={false}
          cancelLabel={<Trans i18nKey="cancel" />}>
          <span>
            <Trans
              i18nKey="scan_code_not_exist"
              values={{ barcode: showBarcodeNotFound }}
            />
          </span>
        </Modal>
      )}
    </div>
  )
}

export default ProductList
