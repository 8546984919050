/* eslint-disable no-unused-vars */
import { useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartUtils } from '@countr/utils'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'

import { sendToDesktopIpc, cancelNixCheck } from '../../../utils/asposqueue'
import { deleteCartEntry } from '../../../utils/cart'
import { setSelectedCategory, setCart } from '../../../store/actions/resource'
import { setFullBasketCheckCart } from '../../../store/actions/notifications'
import {
  setIsCalculatingCart,
  setShowFreeProduct
} from '../../../store/actions/app'

import ProductList from '../Products/ProductList'
import SparCartHeader from './SparCartHeader'
import SparCartItemsList from './SparCartItemsList'
import SparCartFooter from './SparCartFooter'
import CategoriesList from '../Categories/CategoriesList'
import FullBasketCheckLists from './SparCartItemsList/FullBasketCheckLists'
import '../../../styles/sparCart.scss'

const SparCart = ({
  cart,
  store,
  merchantId,
  countrSdk,
  setCartCb,
  handleProduct,
  showNoBarcode,
  setShowNoBarcode,
  requestFullBasketCheck,
  indexedDBInstance
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { selectedCategory } = useSelector(state => state.resource)
  const [showFinishBasketCheck, setShowFinishBasketCheck] = useState(false)
  const [childHeight, setChildHeight] = useState(0)

  const listRef = useCallback(node => {
    if (node !== null) {
      const parentHeight = node.getBoundingClientRect().height
      const remainingHeight = parentHeight - 50
      setChildHeight(remainingHeight / 4)
    }
  }, [])

  const toggleIsCalculatingCart = useCallback(
    show => dispatch(setIsCalculatingCart(show)),
    [dispatch]
  )

  const handleShowNoBarcodeProducts = () => {
    // Navigate to cart
    if (selectedCategory?._id) {
      dispatch(setSelectedCategory({}))
    }

    setShowNoBarcode(true)
  }

  const handleShowFinishBasketCheck = () => {
    setShowFinishBasketCheck(prevState => !prevState)
  }

  const handleRemoveItem = item => {
    try {
      const { getCartEntryId, removeItemFromCart } = CartUtils
      const index = cart.items.findIndex(
        cartEntry => getCartEntryId(cartEntry) === getCartEntryId(item)
      )

      const updatedCart = removeItemFromCart(countrSdk, cart, index)

      if (requestFullBasketCheck) {
        dispatch(setFullBasketCheckCart(updatedCart))
        toggleIsCalculatingCart(false)
        return
      } else {
        dispatch(setCart(updatedCart))
      }

      deleteCartEntry(countrSdk, cart, item)

      const hasNix = cart.items.findIndex(
        item => item.product.options?.minimum_age === 18
      )

      if (!~hasNix) {
        cancelNixCheck()
      }

      const hasZeroPrice = cart.items?.some(item => {
        let currentAddonsTotal = 0

        if (Array.isArray(item.product?.current_addons)) {
          currentAddonsTotal = item.product.current_addons.reduce(
            (acc, crr) => {
              return acc + crr.price
            },
            0
          )
        }

        const totalPriceCalculated =
          currentAddonsTotal + item.product.current_variant.price

        if (totalPriceCalculated === 0) {
          return true
        }

        return false
      })

      if (!hasZeroPrice) {
        dispatch(setShowFreeProduct(false))
      }

      sendToDesktopIpc('asposQueueDispatcher', {
        statusId: 3537,
        type: 'ProductRemoved',
        priority: 1,
        processed: false,
        actionRequired: false,
        extras: {
          info: `Product ${item.product.name} removed from cart id ${cart._id}`
        }
      })

      setTimeout(() => {
        toggleIsCalculatingCart(false)
      }, 1000)
    } catch (e) {
      toggleIsCalculatingCart(false)
      throw Error(`Failed to remove product from cart ${e}`)
    }
  }

  const updateCartServerDebounced = useRef(
    debounce(setCartCb.current, 500, { leading: false, trailing: true })
  )

  const handleAmountChange = (item, action) => {
    const hasZeroPrice = cart.items?.some(item => {
      let currentAddonsTotal = 0

      if (Array.isArray(item.product?.current_addons)) {
        currentAddonsTotal = item.product.current_addons.reduce((acc, crr) => {
          return acc + crr.price
        }, 0)
      }

      const totalPriceCalculated =
        currentAddonsTotal + item.product.current_variant.price

      if (totalPriceCalculated === 0) {
        return true
      }

      return false
    })

    if (!hasZeroPrice) {
      dispatch(setShowFreeProduct(false))
    }

    const newItemStatus = item.status.map(status => {
      if (status.state === 'new') {
        if (action === 'remove') {
          status.amount = status.amount - 1
        } else {
          status.amount = status.amount + 1
        }
      }

      return status
    })

    item.status = newItemStatus

    const updatedCart = CartUtils.changeCartEntryAmount(
      countrSdk,
      cart,
      item,
      action
    )

    if (requestFullBasketCheck) {
      dispatch(setFullBasketCheckCart(updatedCart))
      updateCartServerDebounced.current(updatedCart)
      return
    } else {
      dispatch(setCart(updatedCart))
    }

    updateCartServerDebounced.current(updatedCart)

    sendToDesktopIpc('asposQueueDispatcher', {
      statusId: 3537,
      type: 'QuantityChanged',
      priority: 1,
      processed: false,
      actionRequired: false
    })
  }

  return (
    <div id="spar-cart">
      <SparCartHeader
        showNoBarcodeProducts={showNoBarcode}
        handleShowNoBarcodeProducts={handleShowNoBarcodeProducts}
        setShowNoBarcode={setShowNoBarcode}
      />
      {requestFullBasketCheck && (
        <h2 className="full-basket-check">{t('full_basket_check')}</h2>
      )}
      {!showNoBarcode ? (
        <div className="no-barcode-container">
          <div className="product-container" ref={listRef}>
            <ProductList
              merchantId={merchantId}
              store={store}
              cart={cart}
              handleProduct={handleProduct}
              requestFullBasketCheck={requestFullBasketCheck}
              childHeight={childHeight}
            />
          </div>
          <div className="categories-container">
            <CategoriesList
              merchantId={merchantId}
              store={store}
              setShowNoBarcode={setShowNoBarcode}
            />
          </div>
        </div>
      ) : (
        <div className="cart-container">
          <div
            className={
              showFinishBasketCheck
                ? 'cart-items-container-full'
                : 'cart-items-container'
            }>
            {requestFullBasketCheck ? (
              <FullBasketCheckLists
                showFinishBasketCheck={showFinishBasketCheck}
                handleShowFinishBasketCheck={handleShowFinishBasketCheck}
                updateCartServerDebounced={updateCartServerDebounced}
                countrSdk={countrSdk}
                handleRemoveItem={handleRemoveItem}
                handleAmountChange={handleAmountChange}
                currency={store.currency.symbol}
                store={store}
                merchantId={merchantId}
                handleProduct={handleProduct}
              />
            ) : (
              <SparCartItemsList
                cartItems={cart.items}
                handleRemoveItem={handleRemoveItem}
                handleAmountChange={handleAmountChange}
                currency={store.currency.symbol}
                store={store}
                handleProduct={handleProduct}
                merchantId={merchantId}
                cart={cart}
              />
            )}
          </div>
          {!showFinishBasketCheck && (
            <div className="categories-container">
              <CategoriesList
                indexedDBInstance={indexedDBInstance}
                merchantId={merchantId}
                store={store}
                setShowNoBarcode={setShowNoBarcode}
              />
            </div>
          )}
        </div>
      )}
      <SparCartFooter
        cart={cart}
        showFinishBasketCheck={showFinishBasketCheck}
        handleShowFinishBasketCheck={handleShowFinishBasketCheck}
      />
    </div>
  )
}

export default SparCart
