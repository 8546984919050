import SETTINGS from './../assets/json/settings.json'

export const checkDevice = (sdk, uuid) => {
  return sdk.devices.readOne.detailed(uuid).then(
    device => (!!device && !!device._id ? device : false),
    () => false
  )
}

export const updateDevice = (sdk, device) => {
  return sdk.devices.update(device._id, {
    ...device,
    store: device.store._id,
    employees: device.employees.map(employee => employee._id)
  })
}

export const getLastTransaction = (sdk, device) => {
  return sdk.devices.readOne.lastTransaction(device._id).then(
    last => last,
    () => null
  )
}

export const loadDeviceSettings = device => {
  return !!device?.settings?.admin_pin ? device.settings : SETTINGS
}
