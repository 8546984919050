import {
  getDefaultReceipt,
  returnProductDepositValue,
  getTransactionTaxes,
  getDeliveryCost,
  hashCode
} from './cart'

export const PrinterUtils = (function () {
  const removeSpecialChar = str => {
    return str.replace(/[^\w\s]/gi, '')
  }

  return {
    getTranslations: function (translate) {
      return {
        discount: translate('discount'),
        total: translate('total'),
        subtotal: translate('subtotal'),
        tax: translate('tax'),
        taxes: translate('taxes'),
        tax_rate: translate('tax_rate'),
        net: translate('net'),
        change: translate('change'),
        paid: translate('paid'),
        payment: translate('payment'),
        receipt: translate('receipt'),
        employee: translate('employeeid'),
        customer: translate('customerid'),
        partial_payment: translate('partial_payment'),
        remaining_payment: translate('remaining_payment'),
        deposit: translate('deposit'),
        transactionFee: translate('transactionFee'),
        deliveryCost: translate('deliveryCost'),
        deli: translate('delivery')
      }
    },

    /**
     *
     * @param {Object} transaction
     * @param {Object} userDefaultReceipt
     * @param {Object} store
     * @param {Object} currency
     * @param {Function} callbacks
     * @param {Boolean} printEAN
     */
    receipt: async function (
      device,
      transaction,
      userDefaultReceipt,
      store,
      currency,
      callbacks,
      printEAN,
      printQR,
      openCashDrawer,
      translate
    ) {
      const receipt = {
        header: [],
        items: [],
        total: {},
        footer: [],
        number: '',
        date: '',
        customer: {},
        employee: '',
        currency: currency,
        openCashDrawer,
        info: [],
        reprint: transaction.issued && transaction.issued > 0,
        paymentNumber: 0,
        printEAN: printEAN,
        langs: this.getTranslations(translate),
        giveawayReason: '',
        refund: ''
      }

      let userReceipt = store.receipt
      if (userReceipt === undefined) {
        userReceipt = userDefaultReceipt
      }

      if (userReceipt === undefined) {
        userReceipt = getDefaultReceipt()
      }

      transaction.payments.forEach(payment => {
        if (
          !!payment.card_print_info &&
          !!payment.card_print_info.clientTicket
        ) {
          receipt.info.push(payment.card_print_info.clientTicket)
        }
      })

      receipt.paymentNumber = transaction.payments.length

      // Headers
      userReceipt.header.forEach(head => {
        receipt.header.push(head)
      })

      // Footers
      userReceipt.footer.forEach(foot => {
        if (foot.type !== 'image') {
          receipt.footer.push(foot)
        }
      })

      // Items
      transaction.items.forEach(item => {
        const p = {}
        p.addon = false
        p.name = removeSpecialChar(item.product.name)
        p.current_variant = item.product.current_variant
        p.amount = item.amount
        p.description = item.product.description

        if (item.product.discount < 0) {
          // Negative discount -> new price bigger than old price
          p.price =
            item.product.current_variant.price *
            (1 + Math.abs(item.product.discount))
          p.discount = 0
        } else {
          p.price = item.product.current_variant.price
          p.discount = item.product.discount
        }

        p.note = item.note || ''
        p.addons = []
        p.ean =
          item.product.current_variant.ean &&
          item.product.current_variant.ean.length
            ? item.product.current_variant.ean
            : ''
        p.soldsold_by_weight =
          item.product.options && item.product.options.sold_by_weight
        p.deposit = returnProductDepositValue(item.product)

        if (
          item.product.current_addons !== undefined &&
          item.product.current_addons.length
        ) {
          item.product.current_addons.forEach(addon => {
            p.addons.push({
              name: addon.name,
              amount: addon.amount,
              price: addon.price,
              discount: 0
            })
          })
        }

        receipt.items.push(p)
      })

      let discount = transaction.discount

      if (transaction.reduction && transaction.reduction.numeric) {
        discount =
          transaction.reduction.numeric /
          (parseFloat(transaction.total) + transaction.reduction.numeric)
      }

      receipt.total = {
        total: transaction.total,
        sub_total: transaction.sub_total,
        discount: discount,
        taxes: [],
        paid: [],
        change: transaction.change || 0
      }

      transaction.payments.forEach(payment => {
        const method =
          translate(payment.method) && translate(payment.method).length
            ? translate(payment.method)
            : payment.method
        receipt.total.paid.push({
          type: `${method}${
            !!payment.provider ? `/${translate(payment.provider)}` : ''
          }`,
          value: payment.paid
        })
      })

      const taxes = await getTransactionTaxes(transaction)

      receipt.total.taxes = taxes

      receipt.number = transaction.receipt_id
      receipt.date = transaction.created_at

      if (
        transaction.employee &&
        transaction.employee.name &&
        transaction.employee.name !== 'None'
      ) {
        receipt.employee = transaction.employee.name
      }

      if (transaction.customer && transaction.customer !== null) {
        receipt.customer.name = `${transaction.customer.first_name} 
                                ${transaction.customer.last_name}`
        receipt.customer.email = transaction.customer.email
      }

      // Delivery info check
      if (transaction.extras.delivery && transaction.extras.delivery.print) {
        receipt.delivery = transaction.extras.delivery.print
        receipt.langs.delivery_details = translate('delivery_details')
        receipt.langs.delivery = Object.keys(
          transaction.extras.delivery.print
        ).map(item => translate(item))

        if (
          !!transaction.extras.transactionFee &&
          parseFloat(transaction.extras.transactionFee !== 0)
        ) {
          receipt.transactionFee = transaction.extras.transactionFee
          receipt.total.total += receipt.transactionFee
          receipt.total.paid[0].value += receipt.transactionFee
        }

        if (!!transaction.extras.delivery.deliveryCost) {
          receipt.deliveryCost = getDeliveryCost(transaction)
        }
      }

      // fiscal info
      if (
        !!transaction.fiscal_info &&
        !!transaction.fiscal_info.formattedPrint
      ) {
        receipt.fiscal_info = transaction.fiscal_info.formattedPrint
      }

      // reps & warranties
      if (transaction.extras?.giveaway_reason) {
        receipt.giveawayReason = transaction.extras.giveaway_reason
      }

      // refund
      if (transaction.__t === 'refund' || parseFloat(transaction.total) < 0) {
        receipt.refund = translate('refund')
      }

      if (printQR) {
        const transactionType =
          !transaction.__t || transaction.__t === 'Transaction'
            ? 'transaction'
            : 'refund'
        const merchantHash = hashCode(transaction.merchant)
        receipt.qr = `_${transactionType}|${merchantHash}|${transaction.receipt_id}`
      }

      this.sendToPrinter(device, 'print', receipt, callbacks)
    },

    /**
     * Print receipt image
     *
     * @param {Object} image
     */
    printReceiptImage: function (image) {
      this.sendToPrinter('receipt_image', image)
    },

    /**
     * Main method that control the printers connection
     *
     * @param {String} type
     * @param {Object} payload
     * @param {Function} callbacks
     */
    sendToPrinter: function (
      device,
      type,
      payload,
      callbacks,
      kdsAttached = false
    ) {
      // const isDesktop = DesktopUtils.isDesktop()
      const local = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
      if (!local) {
        return
      }

      const ip = local.local_ip
      const { REACT_APP_WS_PORT } = process.env
      const DYNAMIC_PORT =
        device?.settings?.desktopListenerPort ?? (REACT_APP_WS_PORT || 2222)

      if (local && !!ip) {
        let intervalDesktopConnection
        const ws = new WebSocket(`ws://${local.local_ip}:${DYNAMIC_PORT}`)

        const sendEvent = {
          ip: local,
          type: type,
          payload: payload
        }

        ws.onerror = function error() {
          ws.close()
          !!callbacks && callbacks.setToastMessage('countr_desktop_not_found')
        }

        ws.onmessage = function (event) {
          if (event.data) {
            const msg = JSON.parse(event.data)
            if (msg.event && msg.event === 'print-receipt' && msg.success) {
              const e = new CustomEvent('print-receipt', {
                detail: {
                  id: msg.id
                }
              })
              window.dispatchEvent(e)
            } else if (!!msg.error && !!callbacks.setToastMessage) {
              callbacks.setToastMessage('printer_error', {
                error: msg.error
              })
            } else if (
              msg.success &&
              msg.event !== 'print-receipt' &&
              !!callbacks.setToastMessage
            ) {
              callbacks.setToastMessage('print_successful')
            }
          }
        }

        if (ws?.readyState === 1) {
          ws.send(JSON.stringify(sendEvent))
        } else {
          intervalDesktopConnection = setInterval(() => {
            if (ws?.readyState === 1) {
              ws.send(JSON.stringify(sendEvent))
              ws.close()
              clearInterval(intervalDesktopConnection)
            }
          }, 200)
        }
      } else if (type === 'print-order' && kdsAttached) {
        !!callbacks && callbacks.setToastMessage('send_order_kds')
      }
    },

    isUSBPrinter: function () {
      const desktop = JSON.parse(
        localStorage.getItem('CountrLite:LocalDesktop')
      )

      if (
        !!desktop &&
        !!desktop.printers_saved &&
        !!desktop.printers_saved.length
      ) {
        const printerUSB = desktop.printers_saved.findIndex(
          printer => !!printer.isUSB
        )
        return printerUSB >= 0
      }

      return false
    }
  }
})()
