import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  sendSampleMessage,
  closeFullBasketCheck
} from '../../../../utils/asposqueue'
import countrSdk from '../../../../utils/Countr'
import { setCart } from '../../../../store/actions/resource'
import { setAutodirectPayment } from '../../../../store/actions/app'

import CustomerCardBtn from './CustomerCardBtn'
import TotalSection from './TotalSection'
import ButtonActionSection from './ButtonActionSection'
import TakeawayModal from '../../TakeawayModal'

import '../../../../styles/sparCart.scss'

const SparCartFooter = ({
  cart,
  showFinishBasketCheck,
  handleShowFinishBasketCheck
}) => {
  const FOOD_TYPE = 'Food'
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cart: activeCart } = useSelector(state => state.resource)
  const { requestFullBasketCheck, fullBasketCart, uniqueEventId } = useSelector(
    state => state.notifications
  )
  const [showTakeawayModal, setShowTakeawayModal] = useState(false)

  const handleHasFoodItem = () => {
    return activeCart?.items?.some(i => i?.product?.extras?.type === FOOD_TYPE)
  }

  const handleAsposRandomSampleCheck = async () => {
    navigate('../payment')
    return

    const check = await countrSdk.carts.mustCheck(cart)
    if (check.mustCheck && check.amountOfProductsToCheck && check.reason) {
      // TODO store check
      sendSampleMessage(check, 3539, true)
      dispatch(setAutodirectPayment(true))
    } else {
      navigate('../payment')
    }
  }

  const handleGoToCheckout = async () => {
    if (requestFullBasketCheck) {
      if (fullBasketCart?.total !== activeCart?.total) {
        dispatch(setCart(fullBasketCart))
      }
      closeFullBasketCheck(uniqueEventId)
      navigate('../payment')
    } else if (handleHasFoodItem()) {
      setShowTakeawayModal(true)
    } else {
      handleAsposRandomSampleCheck()
    }
  }

  const handleUpdateKdsOrder = type => {
    const cartCopy = { ...activeCart }
    if (type === 'takeaway') {
      cartCopy.extras.takeaway = true
    } else {
      cartCopy.extras.takeaway = false
    }

    dispatch(setCart(cartCopy))
    setShowTakeawayModal(false)

    return handleAsposRandomSampleCheck()
  }
  return (
    <div id="spar-cart-footer">
      <CustomerCardBtn />
      <TotalSection items={cart.items} />
      <ButtonActionSection
        total={cart.total}
        goToCheckout={handleGoToCheckout}
        showFinishBasketCheck={showFinishBasketCheck}
        handleShowFinishBasketCheck={handleShowFinishBasketCheck}
      />

      {showTakeawayModal && (
        <TakeawayModal
          handleCancel={setShowTakeawayModal.bind(this, false)}
          handleUpdateKdsOrder={handleUpdateKdsOrder}
        />
      )}
    </div>
  )
}

export default SparCartFooter
