import { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Modal } from '@countr/ui'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { CartUtils } from '@countr/utils'
import { useTranslation, Trans } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import ReactPlayer from 'react-player'

import countrSdk from '../../utils/Countr'
import { updateCart } from '../../utils/cart'

import { setCart, setDevice, setSettings } from '../../store/actions/resource'
import { setShowProductDescription } from '../../store/actions/app'

import { updateDevice } from '../../utils/device'
import { sendToDesktopIpc } from '../../utils/asposqueue'
import { cancelNixCheck } from '../../utils/asposqueue'

import './../../styles/screensaver.scss'

const pdfStylingHeight = window.innerHeight * 0.12
const pdfStylingWidth = window.innerWidth * 0.02
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ScreenSaver = props => {
  const navigate = useNavigate()
  const { cart, settings, device } = useSelector(state => state.resource)
  const { t } = useTranslation()
  const [adType, setAdType] = useState('')
  const [activePdfUrl, setActivePdfUrl] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfRotationTime, setPdfRotationTime] = useState(0)
  const [activeImage, setActiveImage] = useState(null)
  const [countdown, setCountdown] = useState(10)
  const timer = useRef(null)
  const dispatch = useDispatch()

  const emptyCart = useCallback(
    cart => {
      if (!cart.items.length) {
        return
      }

      const cleanned = CartUtils.emptyCart(cart)
      updateCart(countrSdk, cleanned)
      dispatch(setCart(cleanned))
    },
    [dispatch]
  )

  const settingShowProductDescription = useCallback(
    (show, description, currentProductName) =>
      dispatch(
        setShowProductDescription({ show, description, currentProductName })
      ),
    [dispatch]
  )

  const updateSettings = useCallback(d => dispatch(setSettings(d)), [dispatch])

  const setDeviceCb = useCallback(
    d => {
      dispatch(setDevice(d))
      updateDevice(countrSdk, d)
    },
    [dispatch]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePhotoAd = () => {
    if (
      device.options?.adInfo?.photoAdUrls &&
      device.options.adInfo.photoAdUrls[0]
    ) {
      setActiveImage(device.options.adInfo.photoAdUrls[0])

      if (device.options.adInfo.photoAdUrls.length > 1) {
        const time = device.options.adInfo.rotationTime
          ? device.options.adInfo.rotationTime
          : 15000 // 15 sec
        handleImageRotation(device.options.adInfo.photoAdUrls, time)
      }
    }
  }

  const handleImageRotation = (images, time) => {
    const count = images.length
    let imageNo = 0
    if (imageNo > 0) {
      setActiveImage('')
    }
    clearInterval(timer.current)

    timer.current = setInterval(
      // TODO handle more animations
      () => {
        const element = document.getElementById('rotating-image')
        if (element) {
          element.classList.remove('fade-in-image')
        }
        imageNo++
        if (imageNo > count - 1) {
          imageNo = 0
        }
        // setNewActive(true)
        setActiveImage(images[imageNo])
        if (element) {
          void element.offsetWidth
          element.classList.add('fade-in-image')
        }
      },
      time ? time : 1000
    )
  }

  const handleReset = (type = 'goToWelcome') => {
    setAdType('')
    setActiveImage(null)
    clearInterval(timer.current)
    setPageNumber(1)
    setCountdown(5)

    props.handleScreenSaver()

    if (props.payment && type === 'cancel') {
      navigate('/payment')
    } else if (type === 'cancel') {
      navigate('/main')
    } else {
      cancelNixCheck()

      navigate('/welcome-page')
      const updatedSettings = {
        ...settings,
        language: 'nl'
      }
      updateSettings(updatedSettings)
      setDeviceCb({
        ...device,
        settings: {
          ...updatedSettings
        }
      })
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    let currentPage = 1
    if (numPages > 1) {
      setInterval(() => {
        currentPage++
        if (currentPage > numPages) {
          currentPage = 1
        }
        setPageNumber(currentPage)
      }, pdfRotationTime)
    }
  }

  const handleCountdown = () => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else {
      setTimeout(() => {
        handleEmptyCart(false)
      }, 1000)
    }

    return ''
  }

  const handleEmptyCart = showAd => {
    emptyCart(cart)
    settingShowProductDescription(false, '')

    sendToDesktopIpc('asposQueueDispatcher', {
      statusId: 3537,
      type: 'EmptyBasket',
      priority: 1,
      processed: false,
      actionRequired: false
    })

    if (!showAd) {
      handleReset()
    }
  }

  useEffect(() => {
    if (!!device?.options?.adInfo?.adType) {
      setAdType(device.options.adInfo.adType)
      if (device.options.adInfo.adType === 'Photos') {
        handlePhotoAd()
      } else if (device.options.adInfo.adType === 'PDF') {
        setActivePdfUrl(device.options.adInfo.pdfAdUrl)
        setPdfRotationTime(device.options.adInfo.pdfRotationTime)
      }
    }
  }, [handlePhotoAd, device.options])

  return (
    <>
      <Modal
        title=""
        handleClose={() => handleReset('cancel')}
        confirmAction={() => handleEmptyCart(false)}
        cancelLabel={<Trans i18nKey="cancel" />}
        backdropClose={false}>
        <h1 className="text-center">{t(`cart_will_empty`)}</h1>
        <h1 className="text-center">{countdown}</h1>
        <h2 className="text-center">{t(`seconds`)}</h2>

        <span>{handleCountdown()}</span>
      </Modal>
      <div className="screensaver-wraper" onClick={handleReset}>
        {adType === 'Video' && (
          <ReactPlayer
            url={device.options?.adInfo?.adUrl}
            playing={true}
            width="100vw"
            height="100vh"
            loop={true}
            volume={0}
            style={{ pointerEvents: 'none' }} // used to disable payers buttons
          />
        )}
        {adType === 'Photos' && (
          <img
            id="rotating-image"
            alt="device-ad"
            className="fade-in-image"
            style={{
              height: '100vh',
              width: `100%`,
              objectFit: 'scale-down'
            }}
            src={activeImage}
          />
        )}
        {adType === 'PDF' && (
          <div
            style={{
              backgroundColor: '#eeeeee',
              marginTop: pdfStylingHeight,
              marginLeft: window.innerWidth > 1450 ? pdfStylingWidth : 0
            }}>
            <Document file={activePdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        )}
      </div>
    </>
  )
}

export default ScreenSaver
