import {
  SET_ALL_RESOURCES,
  SET_UUID,
  SET_AUTH,
  SET_USER,
  SET_DEVICE,
  SET_STORE,
  SET_CART,
  SET_SEARCH,
  SET_LAST_TRANSACTION,
  SET_SETTINGS,
  SET_KEYBOARD,
  SET_SEARCH_VALUE,
  SET_PRODUCTS,
  SET_CATEGORIES,
  SELECTED_CATEGORY,
  SELECTED_BARCODE,
  BARCODE_PRODUCT_NOT_FOUND,
  UPDATE_PRODUCTS_BY_ID,
  DELETE_PRODUCTS_BY_ID
} from './../actions/actionTypes'

const initialState = {
  uuid: '',
  isAuth: false,
  user: {},
  device: {},
  store: {},
  cart: {},
  search: '',
  isSearchRunning: false,
  isKeyboardShown: false,
  lastTransaction: 0,
  settings: [],
  storeProducts: [],
  storeCategories: [],
  selectedCategory: {},
  selectedBarcode: '',
  barcodeProductNotFound: false
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_RESOURCES:
      return {
        ...state,
        ...action.payload,
        isAuth: true
      }
    case SET_UUID:
      return { ...state, uuid: action.payload }
    case SET_AUTH:
      return {
        ...state,
        isAuth: !state.isAuth
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case SET_DEVICE:
      return {
        ...state,
        device: action.payload
      }
    case SET_STORE:
      return {
        ...state,
        store: action.payload
      }
    case SET_CART:
      return {
        ...state,
        cart: action.payload
      }
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
        isSearchRunning: !!action.payload
      }
    case SET_SEARCH_VALUE:
      return {
        ...state,
        search: action.payload
      }
    case SET_KEYBOARD:
      return {
        ...state,
        isKeyboardShown: !!action.payload
      }
    case SET_LAST_TRANSACTION:
      localStorage.setItem('CountrLite:LastTransaction', `${state.device._id}-${action.payload}`)
      return {
        ...state,
        lastTransaction: action.payload
      }
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }
    case SET_PRODUCTS:
      return {
        ...state,
        storeProducts: action.payload
      }
    case UPDATE_PRODUCTS_BY_ID:
      return {
        ...state,
        storeProducts: state.storeProducts.map(product => {
          if (product._id === action.payload._id) {
            product = action.payload
          }
          return product
        })
      }
    case DELETE_PRODUCTS_BY_ID:
      return {
        ...state,
        storeProducts: state.storeProducts.filter(product => {
          if (product._id !== action.payload) {
            return product
          }
        })
      }
    case SET_CATEGORIES:
      return {
        ...state,
        storeCategories: action.payload
      }
    case SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload
      }
    case SELECTED_BARCODE:
      return {
        ...state,
        selectedBarcode: action.payload
      }

    case BARCODE_PRODUCT_NOT_FOUND:
      return {
        ...state,
        barcodeProductNotFound: action.payload
      }
    
    default:
      return state
  }
}

export default appReducer
