const CustomerCardBtn = () => {
  // TODO show points if customer card is scaned
  return (
    <div id="customer-card-btn-container">
      {/* add button back when we have more info about this  */}
      {/* <button className="colored-txt-btn">Scan uw klantpas voor eventuele korting en spaarpunten</button> */}
    </div>
  )
}

export default CustomerCardBtn
