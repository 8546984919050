import { useMemo, useCallback, useEffect, useRef } from 'react'
import { MdDelete } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'

import { CartUtils, ProductUtils, AddonUtils } from '@countr/utils'
import { FormatCurrency } from '@countr/ui'

import { setIsCalculatingCart } from '../../../../store/actions/app'

import { getVariantName, getAddonName } from '../../../../utils/product'
import AddRemoveButtons from './AddRemoveButtons'

import '../../../../styles/sparCart.scss'

const SparCartItem = ({
  product,
  handleRemoveItem,
  handleAmountChange,
  currency,
  disableRemove,
  disableButtons,
  hideExtraInfo
}) => {
  const variantPrice = product?.product?.current_variant?.price
  useEffect(() => {
    if (Number.isNaN(variantPrice)) {
      throw Error('Variant price is not defined')
    }
  }, [variantPrice])

  const cartItemClass = useRef('spar-cart-item')
  const dispatch = useDispatch()
  const toggleIsCalculatingCart = useCallback(
    show => dispatch(setIsCalculatingCart(show)),
    [dispatch]
  )

  const { settings, cart, selectedBarcode, store } = useSelector(
    state => state.resource
  )
  const { requestFullBasketCheck, fullBasketCart } = useSelector(
    state => state.notifications
  )
  const productName = useMemo(
    () => ProductUtils.getProductName(product.product, settings?.language),
    [product.product, settings.language]
  )

  const variantName = useMemo(
    () => getVariantName(product?.product?.current_variant, settings?.language),
    [product.product.current_variant, settings.language]
  )

  const calculateItemPrice = () => {
    let addonsPrice = 0

    if (!!product?.product?.current_addons?.length) {
      addonsPrice = product.product.current_addons.reduce(
        (total, addon) =>
          total + AddonUtils.getAddonPrice(addon, store._id) * addon.amount,
        0
      )
    }

    return variantPrice + addonsPrice
  }

  const totalItemPrice = () => {
    let totalAmountPrice = 0
    if (!product?.product?.reduction) {
      totalAmountPrice = calculateItemPrice() * product.amount || 0
    } else {
      totalAmountPrice =
        (calculateItemPrice() - product?.product?.reduction?.numeric) *
        product.amount
    }

    return totalAmountPrice.toFixed(2)
  }

  const totalReductionPrice = () => {
    const reductionPrice =
      product?.product?.current_variant?.price -
      product?.product?.reduction?.numeric

    return reductionPrice.toFixed(2)
  }

  const handleChangeAmountButton = action => {
    toggleIsCalculatingCart(true)
    handleAmountChange(product, action)
  }

  const handleTileColor = () => {
    let resultClass = 'spar-cart-item-container'

    if (requestFullBasketCheck) {
      const productCartEntryId = CartUtils.getCartEntryId(product)
      const originalCartEntry = cart?.items.find(
        p => CartUtils.getCartEntryId(p) === productCartEntryId
      )
      const fullBasketCheckItem = fullBasketCart?.items.find(
        p => CartUtils.getCartEntryId(p) === productCartEntryId
      )
      if (
        !originalCartEntry?.amount ||
        fullBasketCheckItem?.amount > originalCartEntry.amount
      ) {
        resultClass += ' extra-amount'
      } else if (fullBasketCheckItem?.amount === originalCartEntry.amount) {
        resultClass += ' exact-amount-check'
      }
    }
    return resultClass
  }

  useEffect(() => {
    const handleCheckIsProductEan = code => {
      const productEans =
        product?.product?.current_variant?.ean?.split(',') || []
      return productEans.some(c => c === code)
    }
    if (selectedBarcode && handleCheckIsProductEan(selectedBarcode)) {
      cartItemClass.current = 'spar-cart-item last-scanned-item'
    }

    setTimeout(() => {
      cartItemClass.current = 'spar-cart-item'
    }, 800)
  }, [product?.product?.current_variant?.ean, selectedBarcode])

  return (
    <div className={handleTileColor()}>
      <div className={cartItemClass.current}>
        <div
          className={
            hideExtraInfo
              ? 'spar-image-title-container full-width-title'
              : 'spar-image-title-container'
          }>
          {product?.product?.image ? (
            <img
              src={product?.product?.image} // TODO add placeholder
              alt=""
              className="spar-item-image"
            />
          ) : (
            <span className="spar-item-image spar-item-no-image">
              {productName.slice(0, 3)?.toUpperCase()}
            </span>
          )}
          <div className="product-info">
            <span className="spar-cart-item-title">{productName}</span>
            {!product?.product?.current_variant?.default && (
              <span className="spar-cart-item-extras">
                {variantName}{' '}
                <span className="price-container">
                  <FormatCurrency value={variantPrice} currency={currency} />
                </span>
              </span>
            )}

            {!!product?.product?.current_addons?.length &&
              product.product.current_addons.map(addon => (
                <span className="spar-cart-item-extras" key={addon._id}>
                  {'+ '}
                  {getAddonName(addon, settings?.language)}{' '}
                  {addon.amount > 1 ? ` x ${addon.amount} ` : ''}
                  <span className="price-container">
                    <FormatCurrency value={addon.price} currency={currency} />
                  </span>
                </span>
              ))}
            {product?.product?.options?.deposit?.price && product?.product?.options?.deposit?.description && (
              <span className="spar-cart-item-extras">
                + {product?.product?.options?.deposit?.description} - {currency}
                {product?.product?.options?.deposit?.price.toFixed(2)}
              </span>
            )}
            {product?.product?.options?.deposit?.price && !product?.product?.options?.deposit?.description && (
              <span className="spar-cart-item-extras">
                + <Trans i18nKey="deposit" /> - {currency}
                {product?.product?.options?.deposit?.price.toFixed(2)}
              </span>
            )}
            {product?.product?.reduction?.reason && (
              <span className="spar-cart-item-extras">
                {product?.product?.reduction?.reason}
              </span>
            )}
          </div>
        </div>
        <div className="quantity-buttons">
          <AddRemoveButtons
            productAmount={product?.amount}
            handleChangeAmountButton={handleChangeAmountButton}
            disableButtons={disableButtons}
          />
        </div>

        <div className="spar-item-price-action-container">
          {!hideExtraInfo && (
            <>
              <Trans i18nKey="per_item" />:
              <span
                className={product?.product?.reduction ? 'add-reduction' : ''}>
                {currency || '€'} {parseFloat(product.product.current_variant.price || 0).toFixed(2)}
              </span>
              {product?.product?.reduction && (
                <span>
                  {currency || '€'} {totalReductionPrice()}
                </span>
              )}
              <span>
                <Trans i18nKey="total" />: {currency || '€'} {totalItemPrice()}
              </span>
            </>
          )}
          {!disableRemove && (
            <span
              className="spar-tem-dlt-icon spar-cart-round-btn"
              onClick={() => {
                toggleIsCalculatingCart(true)
                handleRemoveItem(product)
              }}>
              <MdDelete fontSize={24} color="#858585" />
            </span>
          )}
        </div>
      </div>

      {/* TODO change when we know about spar point */}
      {!!product.sparPoint && (
        <div>
          <div className="spar-loyalty-points-container">
            <span>Spaarpunt Aanpassing</span>
            <span>€0.00</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default SparCartItem
