import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { useRef, useState } from 'react'
import './../../styles/keyboard.scss'

const ReactKeyboard = ({
  placeholder,
  onDelete,
  onEnter,
  onChange,
  onKeyPress
}) => {
  const [layout, setLayout] = useState('default')
  const [input, setInput] = useState('')
  const keyboardRef = useRef()

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default'
    setLayout(newLayoutName)
  }

  const keyPressHandler = button => {
    if (button === '{shift}' || button === '{lock}') handleShift()
    else if (button === '{enter}') {
      onEnter && onEnter(input)
    } else if (button === '{bksp}') {
      onDelete && onDelete()
    }

    onKeyPress && onKeyPress(button)
  }
  const changeHandler = button => {
    setInput(button)
    onChange && onChange(button)
  }

  return (
    <div className="keyboard-container">
      <div className="keyboard-input-container">
        <input
          className="keyboard-input-text"
          id="search-input-text"
          value={input}
          type="text"
          placeholder={placeholder}
          onChange={event => setInput(event.target.value)}
          readOnly={true}
        />
      </div>
      <Keyboard
        keyboardRef={r => (keyboardRef.current = r)}
        onChange={changeHandler}
        onKeyPress={keyPressHandler}
        layoutName={layout}
      />
    </div>
  )
}
export default ReactKeyboard
