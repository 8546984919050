import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'

import { setRequestAskForHelp } from '../../../../store/actions/notifications'
import { sendToDesktopIpc } from '../../../../utils/asposqueue'
import countrSdk from '../../../../utils/Countr'
import { logError } from '../../../../utils/error'
import '../../../../styles/sparCart.scss'

const ButtonActionSection = ({
  total,
  goToCheckout,
  showFinishBasketCheck,
  handleShowFinishBasketCheck
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { device, store, merchant, cart } = useSelector(state => state.resource)
  const { requestAskForHelp, requestFullBasketCheck } = useSelector(
    state => state.notifications
  )
  const blockNix = useSelector(state => state.app.showNix)
  const blockFreeProduct = useSelector(state => state.app.showFreeProduct)

  const isCalculatingCart = useSelector(state => state.app.isCalculatingCart)

  const requestAskHelp = useCallback(
    askHelp => dispatch(setRequestAskForHelp(askHelp)),
    [dispatch]
  )

  const callForHelp = () => {
    try {
      sendToDesktopIpc('asposQueueDispatcher', {
        type: 'Help',
        priority: 1,
        processed: false,
        actionRequired: true
      })
    } catch (e) {
      logError(
        countrSdk,
        merchant._id,
        store._id,
        device._id,
        'Failed asposQueueDispatcher',
        e
      )
    }
    requestAskHelp(true)
  }

  return (
    <div className="cart-footer-sections">
      <button
        disabled={requestAskForHelp || requestFullBasketCheck}
        className={`kiosk-btn-container ${
          requestAskForHelp ? 'asking-for-help-btn' : 'light-contained-btn'
        }`}
        onClick={callForHelp}>
        {requestAskForHelp ? t('help_is_coming') : t('ask_for_help')}
      </button>

      {blockNix && (
        <p className="nix-msg">
          <Trans i18nKey="msg_nix" />
        </p>
      )}

      {blockFreeProduct && (
        <p className="nix-msg">
          <Trans i18nKey="free_product" />
        </p>
      )}

      {!showFinishBasketCheck && requestFullBasketCheck ? (
        <>
          <div className="check-amounts-container">
            <p className="text-align-center">
              {t('old')} {t('total')} <br /> €{cart.total.toFixed(2)}
            </p>
            <p className="text-align-center">
              {t('new')} {t('total')} <br />
              {isCalculatingCart
                ? `${t('calculating')}...`
                : `€${total.toFixed(2)}`}
            </p>
          </div>
          <button
            className={'kiosk-btn-container dark-contained-btn'}
            onClick={handleShowFinishBasketCheck}>
            {t('finish_check')}
          </button>
        </>
      ) : (
        <button
          disabled={
            total <= 0 || blockNix || isCalculatingCart || blockFreeProduct
          }
          className={'kiosk-btn-container dark-contained-btn'}
          onClick={goToCheckout}>
          {`${t('pay')}`} €{total.toFixed(2)}
        </button>
      )}
    </div>
  )
}

export default ButtonActionSection
